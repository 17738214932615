$(document).on('click', '.teamMembers__action--toggle', function (e) {
    const parent = $(this).parents('.teamMembers__item');
    parent.siblings().addClass('inactive').removeClass('active');
    parent.addClass('active');

    e.stopPropagation();
});

$(document).on('click', function (e) {
    if ($(e.target).closest('.teamMembers__action--toggle').length === 0) {
        $('.teamMembers__item').removeClass('inactive active');
    }
});

$(document).on('click', '.js-toggle-teammembers', function (t) {
    const parent = $(this).parents('.teamMembers');
    parent.stop().toggleClass('is-expanded');

    t.stopPropagation();
});

$(document).on('click', function (t) {
    if ($(t.target).closest('.js-toggle-teammembers').length === 0) {
        $('.teamMembers').removeClass('is-expanded');
    }
});

$(document).on('click', '.js-add-new-user', function () {
    const box = $('.teamMembers');
    box.stop().addClass('is-new-members');
    $('.teamMembers__notifications').removeClass('is-hidden');
});

$(document).on('click', '.js-close-notification', function () {
    const box = $('.teamMembers');
    $('.teamMembers__notifications').addClass('is-hidden');
});

$(document).on('click', '.js-open-request', function () {
    const box = $('.teamMembers__notifications');
    box.stop().addClass('is-hidden');
    $('.teamMembers').removeClass('is-new-members');
});
