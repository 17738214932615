const fbDB = (() => {
    if (!fbConfig.apiKey || typeof viewData == 'undefined' || !viewData.team.code) {
        return false;
    }

    let initialized = false;
    const teamPath = `${viewData.team.code.toUpperCase()}`;
    const playersPath = `${teamPath}/players`;
    const chatsPath = `${teamPath}/chats`;
    const docPath = `${teamPath}/sharedDoc`;
    const pendingPlayersPath = `${teamPath}/pendingPlayers`;
    let players = [];
    let pendingPlayers = [];
    let playerOnline = [];
    let currentPhase = 1;
    let started = false;
    let finished = false;
    let leader = false;

    const getPlayerPath = (userId) => `${playersPath}/${userId}`;
    const setPlayers = (players) => (fbDB.gamePlayers = players);

    const currentPlayerPath = getPlayerPath(viewData.user.id);

    const getNewFirebaseToken = () => {
        return new Promise((resolve, reject) => {
            $.request('onRequestFirebaseToken', {
                data: {},
                success: function (res) {
                    if (res.success) {
                        resolve(res.token);
                    } else {
                        reject();
                    }
                },
                error: function (err) {
                    reject();
                },
                complete: function (res) {},
            });
        });
    };

    /**
     * Initialzie firebase
     */
    const initFirebase = async () => {
        if (firebase.apps.length === 0) {
            const config = {
                apiKey: fbConfig.apiKey,
                authDomain: fbConfig.authDomain,
                databaseURL: fbConfig.databaseURL,
                projectId: fbConfig.projectId,
                storageBucket: fbConfig.storageBucket,
                messagingSenderId: fbConfig.messagingSenderId,
                appId: fbConfig.appId,
                measurementId: fbConfig.measurementId,
            };

            firebase.initializeApp(config);
        }

        try {
            await fbDB.auth().signInWithCustomToken(fbCustomToken);
        } catch (err) {
            // in case error happen, Get new token and retry to initFirebase again
            const newToken = await getNewFirebaseToken();
            fbCustomToken = newToken;
            return await initFirebase();
        }

        fbDB.db = firebase.database();
        return fbDB;
    };

    /**
     * Initialize game
     */
    const initializeGame = async () => {
        await initFirebase();
        await firebaseListener();
        fbDB.initialized = true;

        // update online state on disconnected
        var ref = firebase.database().ref(currentPlayerPath);
        if (viewData.user.isPendingUser != 1) {
            ref.update({
                onlineState: true,
            });
            ref.onDisconnect().update({
                onlineState: false,
            });
        }

        return fbDB;
    };

    /**
     * Register all firebase listener
     */
    const firebaseListener = async () => {
        await fbDB.db.ref(fbDB.teamPath).once('value', (snapshot) => {
            let data = snapshot.val();
            fbDB.currentPhase = data.currentPhase ?? 1;
            fbDB.started = data.started ?? false;
            fbDB.finished = data.finished ?? false;
            fbDB.players = data.players ?? [];
            fbDB.playerOnline = Object.keys(fbDB.players)
                .filter((key) => fbDB.players[key].onlineState)
                ?.map((player_id) => parseInt(player_id));
            fbDB.leader = data.leader;
        });

        fbDB.db.ref(teamPath).on('value', (snapshot) => bdjs.trigger('bd__teamStateChanged', snapshot.val()));
        fbDB.db
            .ref(playersPath)
            .on('value', (snapshot) => bdjs.trigger('bd__playersStateChanged', snapshot.val()));
        fbDB.db.ref(playersPath).on('child_removed', (snapshot) => {
            bdjs.trigger('bd__playerLeave', snapshot.key);
        });
        fbDB.db
            .ref(chatsPath)
            .on('child_added', (snapshot) => bdjs.trigger('bd__chatsStateChanged', snapshot.val()));

        fbDB.db
            .ref(docPath)
            .on('child_added', (snapshot) => bdjs.trigger('bd__shareDocument', snapshot.val()));

        fbDB.db
            .ref(docPath)
            .on('child_removed', (snapshot) => bdjs.trigger('bd__removeDocument', snapshot.val()));

        fbDB.db
            .ref(pendingPlayersPath)
            .on('child_added', (snapshot) =>
                bdjs.trigger('bd__requestJoinGame', { key: snapshot.key, val: snapshot.val() }),
            );

        fbDB.db
            .ref(pendingPlayersPath)
            .on('child_removed', (snapshot) =>
                bdjs.trigger('bd__rejectJoinGame', { key: snapshot.key, val: snapshot.val() }),
            );
    };

    /* Firebase helper
     * =======================================================================================================================================
     */
    const get = (path) => {
        return fbDB.db.ref(path).get();
    };
    const push = (path, obj) => {
        if (!path || !obj) {
            return false;
        }
        return fbDB.db.ref(path).push(obj);
    };
    const update = (path, obj) => {
        if (!path || !obj) {
            return false;
        }
        return fbDB.db.ref(path).update(obj);
    };
    const remove = (path) => {
        if (!path) {
            return false;
        }
        return fbDB.db.ref(path).remove();
    };
    const onValue = (path, callback) => {
        return fbDB.db.ref(path).on('value', callback);
    };
    const offValue = (path, callback) => {
        return fbDB.db.ref(path).off('value', callback);
    };

    return {
        ...firebase,
        get,
        push,
        update,
        remove,
        onValue,
        offValue,
        initialized,
        initializeGame,
        teamPath,
        playersPath,
        currentPlayerPath,
        currentPhase,
        players,
        started,
        finished,
        playerOnline,
        pendingPlayers,
        leader,
    };
})();
