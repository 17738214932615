$(document).render(function () {
    const isLoginPage = $('#login').length > 0;
    if (!isLoginPage) return false;

    const getDomain = (email) => {
        var parts = email.split('@');
        if (parts.length === 2) {
            return parts[1];
        }
        return false;
    };

    // const checkIsRestricted = (email_domain) => {
    //     var whiteListDomain = emailDomains.split(',');

    //     var isRestricted = true;
    //     if(whiteListDomain && whiteListDomain.length) {
    //         whiteListDomain.forEach(function(val, i) {
    //             val = val.replace(/\/$/, "");
    //             if(val == email_domain) {
    //                 isRestricted = false;
    //             }
    //         });
    //     }

    //     return isRestricted;
    // }

    const isEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    $('#login .login-form').submit(function (e) {
        e.preventDefault();

        const $snackbar = $('.snackbar');
        const showMessage = (message) => {
            $snackbar.find('.snackbar-body').html(message);
            $snackbar.addClass('is-active');
        };

        const $fieldset = $(this).find('fieldset');
        const $submitButton = $(this).find('.login-form-submit');

        $snackbar.removeClass('is-active');
        $fieldset.attr('disabled', true);
        $submitButton.addClass('is-loading');

        const email  = $("#login input[name='email']").val();

        if (!isEmail(email)) {
            showMessage('Invalid email address');
            $submitButton.removeClass('is-loading');
            $fieldset.attr('disabled', false);
            return false;
        }
        // const domain = getDomain(email);
        // const isRestricted = checkIsRestricted(domain);
        // if(isRestricted) {
        //     showMessage('Email domain ['+domain+'] is not registered.');
        //     $submitButton.removeClass('is-loading');
        //     $fieldset.attr('disabled', false);
        //     return false;
        // }

        const postBody = { email };
        $.request('onSubmitEmail', {
            data: postBody,
            success: function (res) {
                if (res.success) {
                    const tokenSentMsg = $('#token-sent-msg').text();
                    showMessage(tokenSentMsg);
                    $('input, button').attr('disabled', 'disabled')
                } else {
                    showMessage(res.error_msg)
                }
            },
            error: function (err) {
                showMessage('Error, please try again');
            },
            complete: function (res) {
                $fieldset.attr('disabled', false);
                $submitButton.removeClass('is-loading');
            },
        });
    });
});
