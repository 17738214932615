class Timer {
    constructor() {
        this.timeoutCursor = null;
    }

    show() {
        if (timer.timeoutCursor) {
            clearTimeout(timer.timeoutCursor);
        }

        $('.navbar-info').addClass('is-active');
        const tick = () => {
            if (gameLogic.finished) return;

            gameLogic.remainingTimeInSeconds--;
            const absRemainingTimeInSeconds = Math.abs(gameLogic.remainingTimeInSeconds);

            const minutes = Math.floor(absRemainingTimeInSeconds / 60);
            const seconds = Math.abs(absRemainingTimeInSeconds % 60);

            if (gameLogic.remainingTimeInSeconds <= 0) {
                $('.navbar-info-timer .navbar-info-timer-minute').text('00');
                $('.navbar-info-timer .navbar-info-timer-second').text('00');
                // show finish game button after 15 minutes extra time
                if(gameLogic.remainingTimeInSeconds <= -900) {
                   $('#finish-game').show(); 
                }      
            } else {
                $('.navbar-info-timer .navbar-info-timer-minute').text(minutes.toString().padStart(2, '0'));
                $('.navbar-info-timer .navbar-info-timer-second').text(seconds.toString().padStart(2, '0'));
            }

            timer.checkTimerAlerts();
            timer.timeoutCursor = setTimeout(tick, 1000);
        };

        tick();
    }

    checkTimerAlerts() {
        if (!viewData.config.timerAlerts) {
            viewData.config.timerAlerts = [];
        }

        //const sortedTimerAlerts = viewData.config.timerAlerts.sort((a, b) => (a.on_minutes > b.on_minutes) ? 1 : ((b.on_minutes > a.on_minutes) ? -1 : 0));
        for (let timerAlert of viewData.config.timerAlerts) {
            const timerAlertOnPhase = parseInt(timerAlert.on_phase.replace('phase', ''))
            const passedPhase = viewData.team.passed_phases ? viewData.team.passed_phases[viewData.team.passed_phases.length - 1] + 1 : 1
            const onTime = (gameLogic.remainingTimeInSeconds == timerAlert.on_minutes * 60)
            const onPhase = (passedPhase == timerAlertOnPhase)

            if (onTime && onPhase) {
                $('.timer-alert-popup').addClass('is-open');
                $('#timer-alert').html(timerAlert.message);
            }
        }

        // if game time hit 0, no need to show hurry animation
        if(gameLogic.remainingTimeInSeconds < 0) return

        if (gameLogic.remainingTimeInSeconds % (5 * 60) === 0) {
            // show notif every 5 minutes
            // console.log('show timer notification (every 5 minutes)');
            $('.navbar-info').removeClass('is-notify');
            $('.navbar-info')[0].offsetWidth;
            $('.navbar-info').addClass('is-notify');
        }

        if (gameLogic.remainingTimeInSeconds == 5 * 60) {
            $('.navbar-info').addClass('is-hurry-3');
        } else if (gameLogic.remainingTimeInSeconds == 2 * 60) {
            $('.navbar-info').addClass('is-hurry-2');
        } else if (gameLogic.remainingTimeInSeconds == 30) {
            $('.navbar-info').addClass('is-hurry');
        }
    }
}

const timer = new Timer();
