$(document).render(function () {
    const isEndPage = $('#end-page').length > 0;
    if (!isEndPage) return false;

    $('.navbar-info').hide();
    $('.teamMembers').hide();

    const elms = {
        slider: '.swiper-container',
        sliderItem: '.swiper-slide',
        sliderPagination: '.swiper-pagination',
        sliderPrev: '.swiper-button-prev',
        sliderNext: '.swiper-button-next',
    };

    const data = { team_id: globalTeamId };
    $.request('onGetRemainingTime', {
        data,
        success: function (data) {
            $('#remaining-time').text(data.remaining_time);
        },
        error: function () {
            $('#remaining-time').text('00:00');
        },
    });

    $.request('onGetScore', {
        data,
        success: function (data) {
            $('#ranking-percentage').text(data.ranking_score);
        },
        error: function () {
            $('#ranking-percentage').text('0');
        },
    });

    const slider = new Swiper(elms.slider, {
        init: false,
        pagination: {
            el: elms.sliderPagination,
        },
        navigation: {
            nextEl: elms.sliderNext,
            prevEl: elms.sliderPrev,
        },
    });

    const syncSliderControls = function () {
        const hasActiveIndex = slider && slider.activeIndex ? true : false;
        const currentIndex = hasActiveIndex ? parseInt(slider.activeIndex) : 0;
        const slidesLength = parseInt(slider.slides.length);
        const disabledPrev = currentIndex === 0 ? true : false;
        const disabledNext = currentIndex === slidesLength - 1 || slidesLength < 1 ? true : false;
        $(elms.sliderPrev).prop('disabled', disabledPrev);
        $(elms.sliderNext).prop('disabled', disabledNext);
    };

    slider.on('init', syncSliderControls);
    slider.on('slideChange', syncSliderControls);
    slider.init();

    // $(elms.sliderPrev).on('click', function () {
    //     slider.slidePrev();
    // });

    // $(elms.sliderNext).on('click', function () {
    //     slider.slideNext();
    // });
});
