function getTransitionEndEvent() {
    let i;
    const el = document.createElement('div');
    const transitions = {
        transition: 'transitionend',
        OTransition: 'otransitionend', // oTransitionEnd in very old Opera
        MozTransition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd',
    };

    for (i in transitions) {
        const isExist = transitions.hasOwnProperty(i);
        const isNotUndefined = typeof el.style[i] !== 'undefined';
        if (isExist && isNotUndefined) return transitions[i];
    }
}
