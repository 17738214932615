$(document).render(function () {
    const isChooseRoomPage = $('#choose-room').length > 0;
    if (!isChooseRoomPage) return false;

    $('.room-item-date').each(function (index, el) {
        const timestamp = $(this).data('timestamp');
        const dateStr = new Date(timestamp * 1000);
        $(this).text(dateStr.toLocaleDateString() + ' ' + dateStr.toLocaleTimeString());
    });
});
