class Realtime {
    constructor() {
        this.members = []; // { email: string, avatar: url, room: string }
        this.options = {};
        this.pusher = null;
        this.channel = null;
        this.onlineCounter = 0;
        this.readyMembers = [];
        this.pendingDelete = [];
    }

    init(options) {
        return;
        this.options = options;
        this.readyMembers = options.readyMembers ? options.readyMembers : [];
        this.pusher = new Pusher(options.key, { cluster: options.cluster });
        this.channel = this.pusher.subscribe('presence-' + options.room);
    }

    // ---------------------------------------------------------------------------------- waiting room

    listenWaitingRoom() {
        return;
        const updateTeamOnlineCounter = () => {
            return;
            $.request('onUpdateOnlineCounter', {
                data: {
                    online_counter: realtime.channel.members.count,
                },
                success: function (res) {
                    //console.log('team online counter updated');
                },
                error: function (qXHR, textStatus, errorThrown) {
                    console.log(errorThrown);
                },
            });
        };

        const setTeamMemberInactive = (memberId) => {
            return;
            $.request('onSetTeamMemberInactive', {
                data: {
                    teamId: viewData.team.id,
                    userId: memberId,
                },
                success: function (res) {
                    //console.log('team online counter updated');
                },
            });
        };

        const addMemberToUserList = (member) => {
            return;
            realtime.pendingDelete[member.id] = false;

            if ($('#user-' + member.id).length) return;

            // const userEl = document.createElement('span');
            // userEl.id = 'user-' + member.id;
            // userEl.innerText = member.info.name;
            // document.querySelector('#waiting-room-registered-users').appendChild(userEl);

            const userEl =
                `<div class="game-player__item" id="user-` +
                member.id +
                `">
                            <div class="game-player__avatar" style="background-image: url('` +
                (member.info.avatar
                    ? member.info.avatar
                    : 'https://cdn-images-1.medium.com/fit/c/100/100/1*3ApyPDl5_ovc87cyvX7MMw.jpeg') +
                `');">
                            </div>
                            <div class="game-player__name">
                                ` +
                member.info.name +
                `
                            </div>` +
                (viewData.user.isLeader && viewData.user.id != member.id
                    ? `<button class="btn btn-primary game-player__delete" user-id="` +
                    member.id +
                    `" player-name="` +
                    member.info.name +
                    `">Delete</button>`
                    : '') +
                `</div>`;

            $('#waiting-room-registered-users').append(userEl);

            this.onlineCounter++;
            updateTeamOnlineCounter();
            realtime.allowStartGame();
        };

        const removeMemberFromUserList = (member) => {
            return;
            realtime.pendingDelete[member.id] = true;

            var timeout = setTimeout(() => {
                if (!realtime.pendingDelete[member.id]) return clearTimeout(timeout);

                const userEl = document.getElementById('user-' + member.id);
                if (userEl === null) return;

                userEl.parentNode.removeChild(userEl);
                this.onlineCounter--;
                updateTeamOnlineCounter();
                setTeamMemberInactive(member.id);

                realtime.readyMembers = helper.removeItemOnce(realtime.readyMembers, member.id);
                realtime.updateRoomMembersWaitingRoom();
                realtime.allowStartGame();
            }, 3000);
        };

        this.channel.bind('pusher:subscription_succeeded', () => {
            return;
            this.channel.members.each((member) => addMemberToUserList(member));
            this.setViewReadyWaitingRoom();

            $('.game-info').addClass('is-open');
            realtime.allowStartGame();
        });

        this.channel.bind('pusher:member_added', (member) => {
            return;
            addMemberToUserList(member);
            this.setViewReadyWaitingRoom();
        });
        this.channel.bind('pusher:member_removed', removeMemberFromUserList);
        this.channel.bind('client-waiting-room-ready', this.onWaitingRoomReadyChange);
        this.channel.bind('client-chat', this.onChat);
        this.channel.bind('client-start-game', this.onStartGame);
        this.channel.bind('client-change-team-name', this.onChangeTeamName);
        this.channel.bind('client-kick', this.onKick);

        this.channel.bind('client-change-leader', (data) => {
            return;
            if (data.leaderEmail === viewData.user.email) {
                window.location.href = '/waiting-room?new-leader=1';
            }
        });
    }

    // !!! NOT USED !!!
    allowStartGame() {
        return;

        if (realtime.onlineCounter < 2) {
            return $('#start-game').attr('disabled', 'disabled');
        } else {
            return $('#start-game').removeAttr('disabled');
        }
    }

    onKick(eventData) {
        return;
        if (eventData.userId == viewData.user.id) {
            // TODO add action kick
            console.log('I am kicked');
            $.request('onKick', {
                success: function (res) {
                    window.location.href = '/?kick=1';
                },
                error: function () {
                    console.log('error occurend');
                },
            });
        }
    }

    onChangeTeamName(eventData) {
        return;
        var msg = $('#team-name-status').data('change-team-name');
        msg = msg.replace('{name}', eventData.sender);
        showMessage(msg);

        if (!eventData.local) {
            $('#team-name').val(eventData.teamName);
        }
    }

    updateRoomMembersWaitingRoom() {
        return;
        $.post('team/ready-members', {
            room_id: realtime.options.room,
            ready_members: JSON.stringify(realtime.readyMembers),
        });
    }

    onWaitingRoomReadyChange(eventData) {
        return;
        $('#user-' + eventData.userId).toggleClass('is-ready');

        if (eventData.isReady) {
            realtime.readyMembers.push(eventData.userId);
        } else {
            realtime.readyMembers = helper.removeItemOnce(realtime.readyMembers, eventData.userId);
        }

        realtime.readyMembers = [...new Set(realtime.readyMembers)];

        if (eventData.local) {
            $('#start-btn').text($('#start-btn').data(eventData.isReady ? 'cancel' : 'ready'));
            $('#start-btn').toggleClass('is-ready');
            realtime.updateRoomMembersWaitingRoom();
        }
    }

    onChat(data) {
        return;
        var n = document.createTextNode(data.sender);
        var m = document.createTextNode(data.message);
        AspirationUI.append(n, m, data.avatar);
    }

    onStartGame() {
        return;
        var gameProgressCreated = false;

        const createGameProgress = () => {
            // make sure game progress is created, retry if this fails
            $.post('game-progress/create', function () {
                gameProgressCreated = true;
            }).fail(function (xhr, status, error) {
                setTimeout(createGameProgress, 1000);
            });
        };

        const startCountdown = () => {
            let counter = 5;
            const counterInterval = setInterval(() => {
                counter = counter - 1;
                $('.game-start__counter span').html(counter);

                if (counter === 0) {
                    clearInterval(counterInterval);
                    $('.game-start__counter').addClass('is-loading');
                    document.location.href = '/main-office';
                    //$('#go-to-main-office').click();
                }
            }, 1000);

            $(".teamMembers").addClass("is-faded");
            $('.sidepopup').addClass('is-hidden');
            $('.game-start').addClass('is-active');
            $('.game-start__counter span').html(counter);
        };


        $(".teamMembers").addClass("is-faded");
        createGameProgress();
        startCountdown();
        analytic.logEvent('start_game', {});
    }

    setViewReadyWaitingRoom() {
        return;
        for (let member of realtime.readyMembers) {
            $('#user-' + member).addClass('is-ready');
        }

        const currUserId = realtime.options.user.id.toString();
        if (realtime.readyMembers.includes(currUserId)) {
            $('#start-btn').text($('#start-btn').data('cancel'));
            $('#start-btn').addClass('is-ready');
        }
    }

    // ---------------------------------------------------------------------------------- in game

    listenInGame() {
        return;
        const updateTeamOnlineCounter = () => {
            return;
            $.request('onUpdateOnlineCounter', {
                data: {
                    online_counter: realtime.channel.members.count,
                },
                success: function (res) {
                    //console.log('team online counter updated');
                },
            });
        };

        const getCurrentPage = () => {
            return window.location.pathname.replace('/', '');
        };

        const removeMember = (member) => {
            for (let player of gameLogic.players) {
                if (player.id == member.id) {
                    player.page = '';
                }
            }

            updateTeamOnlineCounter();
            $.post('game-progress/change-page', { page: '', user_id: member.id });
            setTimeout(() => realtime.setViewPlayersInRoom('removeMember'), 1); // run on next tick, because need time to render elements
        };

        const addMember = (member) => {
            updateTeamOnlineCounter();
            realtime.setViewPlayersInRoom('add-member');
        };

        realtime.channel.bind('pusher:member_added', addMember);
        realtime.channel.bind('pusher:member_removed', removeMember);
        realtime.channel.bind('client-change-page', realtime.onChangePage);

        realtime.channel.bind('pusher:subscription_succeeded', function () {
            // never logout the leader
            if (viewData.user.isLeader) return;

            if (realtime.channel.members.count > viewData.config.maxTeamMembers) {
                window.location.href = '/?max-participant';
            }
        });
    }

    onChangePage(eventData) {
        return;
        for (let player of gameLogic.players) {
            if (player.id == eventData.userId) {
                player.page = eventData.page;
            }
        }

        $.post('game-progress/change-page', { page: eventData.page, user_id: eventData.userId });
        setTimeout(() => realtime.setViewPlayersInRoom('change-page'), 1); // run on next tick, because need time to render elements
    }

    // ---------------------------------------------------------------------------------- document

    listenShareDocument() {
        return;
        realtime.channel.bind('client-share-document', realtime.onShareDocument);
        realtime.channel.bind('client-remove-document', realtime.onRemoveDocument);
    }

    onShareDocument(eventData) {
        return;
        // add document to toolbar
        var documentExist = false;
        // var documents = []

        $('.toolbox-popup-documents .swiper-slide').each((index, document) => {
            const title = $(document).data('title');
            const url = $(document).data('url');
            if (title == eventData.doc_title && url == eventData.doc_url) {
                documentExist = true;
            }
        });

        if (!documentExist) {
            var img_name = '/' + eventData.doc_url.split('/').pop();
            $.request('onGetThumbnail', {
                data: { doc_url: helper.safeUrl(eventData.doc_url /*img_name*/) },
                success: function (res) {
                    // Swiper index also start from 0
                    const newIndex = toolboxPopupSlider.slides.length;
                    const { doc_url, doc_title } = eventData;
                    const slideMarkup = `<div
                        class="swiper-slide"
                        data-title="${doc_title}"
                        style="background-image:url('${helper.safeUrl(doc_url)}')"
                        doc_url="${doc_url}"></div>`;
                    const thumbMarkup = `<button
                        class="toolbox-popup-thumb-item ${newIndex ? 'is-active' : ''}"
                        style="background-image:url('${helper.safeUrl(res.result)}')"
                        data-url="${doc_url}"
                        data-title="${doc_title}"
                        ></button>`;
                    const fullscreenMarkup = `<img
                        src="${doc_url}"
                        data-link="${doc_url}"
                        data-title="${doc_title}"
                        alt="${doc_title}"
                    >`;

                    $('.toolbox-popup-thumb-wrapper').append(thumbMarkup);
                    toolboxPopupSlider.appendSlide(slideMarkup);
                    $('.toolbox-fullscreen-popup .toolbox-fullscreen').append(fullscreenMarkup);
                    toolboxPopupSlider.update();
                    syncSliderControls();
                },
                error: function (error) { },
            });
        }

        // increase notification
        $('#notification-badge-document').show();
        $('#notification-badge-total').show();

        var count = $('#notification-document').data('count');
        count++;

        $('#notification-document').text(`+${count}`);
        $('#notification-document').data('count', count);
        calculateNotificationTotal();
    }

    onRemoveDocument(eventData) {
        return;
        // add document to toolbar
        var slidesIndex;

        $('.toolbox-popup-documents .swiper-slide').each((index, element) => {
            if (
                $(element).attr('doc_url') == eventData.doc_url &&
                $(element).data('title') == eventData.doc_title
            ) {
                return (slidesIndex = index);
            }
        });

        $(
            `.toolbox-popup-thumb-item[data-url="${eventData.doc_url}"][data-title="${eventData.doc_title}"]`,
        ).remove();
        $(
            `.toolbox-fullscreen-popup img[src="${eventData.doc_url}"][data-title="${eventData.doc_title}"]`,
        ).remove();

        // console.log('slidesIndex', slidesIndex)
        if (typeof slidesIndex !== 'undefined') {
            toolboxPopupSlider.removeSlide(slidesIndex);
            toolboxPopupSlider.update();
            syncSliderControls();

            if (!toolboxPopupSlider.slides.length) {
                $('.toolbox-popup-control-title').text($('.toolbox-popup-control-title').data('no-document'));
            }
        }

        realtime.hideShareDocumentBtnIfAlreadyShared();
    }

    hideShareDocumentBtnIfAlreadyShared() {
        var savedDocuments = viewData.team.saved_documents;
        if (!savedDocuments) return false;

        $('.document-list a').removeClass('is-shared');
        for (let savedDocument of savedDocuments) {
            $(
                `.document-list a[data-title="${savedDocument.title}"][data-link="${savedDocument.url}"]`,
            ).addClass('is-shared');
        }

        // hide share button for email statement && hotspot
        if ($('.playroom-imagebox').hasClass('is-open')) {
            share.hideShareButtonIfShared();
        } else if ($('#popup-ipad').hasClass('is-open')) {
            share.hideShareButtonIfShared();
        }
    }

    listenGoToPhase2() {
        return;
        realtime.channel.bind('client-go-to-phase2', realtime.onGoToPhase2);
    }

    listenGoToPhase3() {
        return;
        realtime.channel.bind('client-go-to-phase3', realtime.onGoToPhase3);
    }

    listenGoToPhase4() {
        return;
        realtime.channel.bind('client-go-to-phase4', realtime.onGoToPhase4);
    }

    onGoToPhase2() {
        return;
        // unlockDataRoom
        $('#data-room-btn .playroom-room-name').removeClass('is-disabled');

        // show briefing phase 2
        $('.toolbox-briefing .briefing-step.is-active').removeClass('is-active');
        $('#briefing-step-2').addClass('is-active').show();
        realtime.increaseNotificationBriefing();
        gameLogic.showSingleBrief(2);

        // visualize phase
        $('.navbar-info').removeClass('is-phase-1').addClass('is-phase-2');
    }

    onGoToPhase3() {
        return;
        // show briefing phase 3
        $('.toolbox-briefing .briefing-step.is-active').removeClass('is-active');
        $('#briefing-step-3').addClass('is-active').show();
        realtime.increaseNotificationBriefing();
        gameLogic.showSingleBrief(3);

        // visualize phase
        $('.navbar-info').removeClass('is-phase-2').addClass('is-phase-3');
    }

    onGoToPhase4() {
        return;
        // show briefing phase 4
        if (!$('#brief-title-4').hasClass('shown')) {
            $('.toolbox-briefing .briefing-step.is-active').removeClass('is-active');
            $('#briefing-step-4').addClass('is-active').show();
            realtime.increaseNotificationBriefing();
            gameLogic.showSingleBrief(4);

            // visualize phase
            $('.navbar-info').removeClass('is-phase-3').addClass('is-phase-4');
            $('#brief-title-4').addClass('shown');
        }
    }

    increaseNotificationBriefing() {
        return;
        // increase notification briefing
        $('#notification-badge-briefing').show();
        // $('#notification-badge-total').show();
        var count = $('#notification-briefing').data('count');
        count++;
        $('#notification-briefing').text(`+${count}`);
        $('#notification-briefing').data('count', count);
        calculateNotificationTotal();

        $.request('onIncreaseNotificationBriefing', {
            data: {},
            success: function (res) { },
            error: function (error) { },
        });
    }

    listenEmailInbox() {
        return;
        realtime.channel.bind('client-email-inbox', realtime.onEmailInbox);
    }

    loadInbox(isFirst) {
        const getCurrentPage = () => {
            return window.location.pathname.replace('/', '');
        };

        if (!inGamePages.includes(getCurrentPage())) return;

        $.request('onGetInbox', {
            data: {},
            success: function (res) {
                laptop.inbox = res;

                var index = 0;
                $('.email-messages').html('');
                for (let inbox of res) {
                    const date = new Date(inbox.time * 1000);
                    const time =
                        date.getHours().toString().padStart(2, '0') +
                        ':' +
                        date.getMinutes().toString().padStart(2, '0');

                    $('.email-messages').append(`
                        <button class="message ${inbox.is_read ? 'read' : 'unread'}" data-index="${index}">
                            <div class="message-header">
                                <div class="message-title">
                                    ${inbox.email_sender}
                                </div>
                                <div class="message-timestamp">
                                    ${time}
                                </div>
                            </div>
                            <div class="message-content">
                                ${inbox.email_subject}
                            </div>
                        </button>
                    `);
                    index++;
                }

                if (isFirst) {
                    // for member default email view is inbox
                    try {
                        if (!viewData.user.isLeader) {
                            $('.email-messages .message:first').click();
                        } else {
                            $('.email-compose').click();
                        }
                    } catch (e) { }
                } else {
                    try {
                        if (viewData.user.isLeader) {
                            $('.email-messages .message:first').click();
                        }
                    } catch (e) { }
                }
            },
            error: function (error) {
                try {
                    showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                } catch (e) {
                    showMessage('Oops, an error occured.');
                }
            },
        });
    }

    onEmailInbox(eventData) {
        return;
        realtime.loadInbox();

        if (eventData.phase === 1) {
            phase1Passed = eventData.res.is_correct;
        } else if (eventData.phase === 2) {
            phase2Passed = eventData.res.is_correct;
        } else if (eventData.phase === 3) {
            window.phase3Passed = eventData.res.is_correct;
        }
    }

    listenRefreshViewData() {
        return;
        realtime.channel.bind('client-refresh-view-data', realtime.onRefreshViewData);
    }

    onRefreshViewData(eventData) {
        return;
        $.request('onRefreshViewData', {
            data: {},
            success: function (res) {
                viewData = res;
                if (eventData.callback) {
                    realtime[eventData.callback]();
                }
            },
            error: function (error) { },
        });
    }

    listenCallCEO() {
        return;
        realtime.channel.bind('client-call-ceo', realtime.onCallCEO);
    }

    onCallCEO(eventData) {
        return;
        $('.toolbox').removeClass('is-open');
        $('.toolbox-phone').addClass('is-open');

        const $fieldset = $('.phone-call fieldset');
        $('.phone').addClass('is-calling');
        $fieldset.prop('disabled', true);

        const $player = $('#voicemail-player');
        if (eventData.audio) {
            $player.attr('src', eventData.audio);
            $player[0].load();
        }

        setTimeout(() => {
            $('.phone').addClass('is-voicemail');
        }, 1000);
    }

    listenOnFinish() {
        return;
        realtime.channel.bind('client-on-finish', realtime.onFinish);
    }

    onFinish(eventData) {
        return;
        setTimeout(() => {
            var $video = $('#outro-video');
            var $laptop = $('.laptop');
            if ($video.length) {
                setupOutroVideo();
            }
        }, 800);

        const markAsFinish = () => {
            gameLogic.finished = true;
            localStorage.setItem('teamId', viewData.team.id); // used by end-page.js

            $.request('onFinishGame', {
                data: {},
                success: function (res) { },
                error: function (error) {
                    // make sure this request is submitted
                    setTimeout(markAsFinish, 1000);
                },
            });
        };

        markAsFinish();
    }

    listenGotoDataRoom() {
        return;
        realtime.channel.bind('client-go-to-dataroom', realtime.onGotoDataRoom);
    }

    onGotoDataRoom() {
        return;
        const isOpeningDataRoomPassword = $('.main-office-dataroom').hasClass('is-open');
        // console.log('isOpeningDataRoomPassword', isOpeningDataRoomPassword)
        if (isOpeningDataRoomPassword) {
            $('#go-to-data-room').click();
        }
    }
}

const realtime = new Realtime();
