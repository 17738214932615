const BD_Helper = {
	removeItemOnce: (arr, value) => {
		var index = arr.indexOf(value);
		if (index > -1) {
			arr.splice(index, 1);
		}
		return arr;
	},
	escapeHtml (string) {
		var entityMap = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#39;',
			'/': '&#x2F;',
			'`': '&#x60;',
			'=': '&#x3D;'
		  };

		return String(string).replace(/[&<>"'`=\/]/g, function (s) {
		  return entityMap[s];
		});
	},

	isUndefined(value){
		return typeof value == 'undefined';
	},

	updateRoomRequirements() {
		const rooms = CFG.game.rooms;
		const roomStatus = CFG.game.roomStatus;

		$.each(rooms, function (index, room) {
			const requirements = room.requirements;
			const $roomItem    = $(`.js-room-item[data-page="${room.id}"]`);
			
			if (!requirements.length) {
				$roomItem.removeClass('is-locked');
				return true;
			}

			let isLocked = false;
			$.each(requirements, function (index, roomID) { 
				if(typeof roomStatus[roomID] == 'undefined' || roomStatus[roomID] !== 'is-status-completed') {
					isLocked = true;
					return false;
				}
			});

			(isLocked) ? $roomItem.addClass('is-locked') : $roomItem.removeClass('is-locked');
		});
		
	},

	/**
	 * @example
	 * const object = {
	 *  1: { name: "Foo" },
	 *  2: { name: "Bar" }
	 * };
	 * const result = indexedObjectToArray(object);
	 * console.log(result);
	 * // output: [{ name: "Foo" }, { name: "Bar" }]
	 * @param {Object} obj
	 */
	indexedObjectToArray(obj) {
		if (!obj) return [];
		if (typeof obj !== "object") return [];
		return Object.keys(obj).map((key) => obj[key]);
	}

};
