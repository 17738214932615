class Tutorial {
    constructor({ id, highlighted, onClose }) {
        this.id = id;
        this.highlighted = highlighted;
        this.onClose = onClose || null;

        this.elms = {
            root: '.tutorial',
            close: '.tutorial-close',
            svgPath: '.tutorial-svg path',
            position: '.tutorial-position',
        };

        this._popper = null;

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.registerEvents = this.registerEvents.bind(this);
        this.registerEvents();
    }

    registerEvents() {
        const self = this;
        const elms = this.elms;
        const id = this.id;

        $(`${id}${elms.root} ${elms.close}`).on('click', self.close);
    }

    close() {
        const id = this.id;
        const elms = this.elms;

        const $tutorial = $(`${id}${elms.root}`);
        $tutorial.removeClass('is-open');
        if (typeof this.onClose === 'function') this.onClose();
    }

    open() {
        const highlighted = this.highlighted;
        const id = this.id;
        const elms = this.elms;

        const $tutorial = $(`${id}${elms.root}`);
        const $position = $tutorial.find(elms.position);
        const $svgPath = $tutorial.find(elms.svgPath);

        // Calculate Y coordinate
        const yPercent = 100 / $(window).innerHeight();
        const height = $(highlighted).innerHeight() * yPercent;
        const y1 = $(highlighted).offset().top * yPercent;
        const y2 = y1 + height;

        // Calculate X coordinate
        const xPercent = 100 / $(window).innerWidth();
        const width = $(highlighted).innerWidth() * xPercent;
        const x1 = $(highlighted).offset().left * xPercent;
        const x2 = x1 + width;

        // Subtract and set the path
        let d = 'M 0,0 L100,0 L100,100 L0,100z';
        d += ` M ${x1},${y1} L${x2},${y1} L${x2},${y2} L${x1},${y2}z`;
        $svgPath.attr('d', d);

        $tutorial.addClass('is-open');

        if (!this._popper) {
            this._popper = Popper.createPopper($(highlighted)[0], $position[0], {
                placement: 'right',
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            mainAxis: true,
                            altAxis: true,
                        },
                    },
                ],
            });
        }
    }

    onViewedTutorial(tutorial_type) {
        $.request('onViewedTutorial', {
            data: { tutorial_type },
            success: function (res) {
               
            },
            error: function (error) {},
            complete: function () {},
        });
    }
}

const tutorialObj = {};
