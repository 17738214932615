$(document).render(function () {
    $('[data-open="popup"]').click(function () {
        const popupID = $(this).data('target');
        const $popup = popupID ? $(popupID) : null;

        // console.log(popupID, $popup);

        if ($popup) $popup.addClass('is-open');
    });

    $('[data-dismiss="popup"]').click(function () {
        const $popup = $(this).parents('.popup').first();
        $popup.removeClass('is-open');
    });
});
