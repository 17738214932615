$(document).render(function() {

	$('.submit-role').on('click', function (e) {
        submitRole($(this))
    });

    const $snackbar = $('.snackbar');

    const showMessage = (message) => {
        $snackbar.find('.snackbar-body').html(message);
        $snackbar.addClass('is-active');
    };

    const hideMessage = () => {
         $snackbar.removeClass('is-active');
    }

    const submitRole = (el) => {
        el.addClass('is-loading');
        hideMessage();
    	var role = el.attr('id');
    	var ajaxProps = {
            'role'  : role
        };

        el.request('onSubmitRole', {
            data: ajaxProps,
            success: function(data) {
                if(data.success) {
                    if(role === 'leader') {
                    		const user = data.user
                				analytic.setUserProperties({
                					id: user.id,
                					created_at: user.created_at,
                					group_leader: 1,
                					browser: helper.getBrowser(),
                				});
                        $('#go-to-create-room').click();
                    } else {
                        $('#go-to-subscribe-avatar').click();
                    }
                } else {
                    showMessage(data.error);
                }
            },
            error: function(error) {
                try {
                    showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                } catch(e) {
                    showMessage('Oops, an error occured.');
                }
            },
            complete: function() {
                el.removeClass('is-loading');
            }
        });
    };

});