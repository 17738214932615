const PlayroomSetup = () => {
    if ($('.playroom').length < 1) return false;

    const startedAt = +new Date();
    const $playroom = $('.playroom').last();
    const $playroomImage = $playroom.find('.playroom-image');
    const $playroomWrapper = $playroom.find('.playroom-wrapper');

    // show error if onLoad not called more than 10s
    const waitLoad = setInterval(() => {
        const now = +new Date();
        const diff = now - startedAt;
        if (diff > 10 * 1000) {
            showMessage(viewData.strings.playroom_image_error_load);
            clearInterval(waitLoad);
        }
    }, 100);

    const calculate = () => {
        // console.log('PlayroomSetup - calculate');

        const imageRatio = $playroomImage.height() / $playroomImage.width();
        const containerRatio = $playroom.height() / $playroom.width();

        let finalHeight;
        let finalWidth;

        if (containerRatio > imageRatio) {
            finalHeight = $playroom.height();
            finalWidth = $playroom.height() / imageRatio;
        } else {
            finalWidth = $playroom.width();
            finalHeight = $playroom.width() * imageRatio;
        }

        $playroomWrapper.css({
            height: finalHeight + 'px',
            width: finalWidth + 'px',
        });

        $playroomImage.css({
            height: finalHeight + 'px',
            width: finalWidth + 'px',
        });

        $playroom.addClass('is-calculated');
    };

    const markAsLoaded = () => {
        clearInterval(waitLoad);
    };

    const onLoad = () => {
        calculate();
        markAsLoaded();
    };

    // console.log('PlayroomSetup', $playroomImage[0], $playroomImage[0].complete);

    // if image loaded directly from cached file, it wont trigger onload
    if ($playroomImage[0].complete) onLoad();
    $playroomImage.on('load', onLoad);
};

$(document).render(function () {
    const isPlayroomExist = $('.playroom').length;
    if (!isPlayroomExist) return false;

    const elms = {
        imagebox: '.playroom-imagebox',
        imageboxScrolling: '.playroom-imagebox .popup-inner',
        imageboxContent: '.playroom-imagebox-content',
        imageboxBar: '.playroom-imagebox-bar',
        imageboxImg: '.playroom-imagebox-image img',
        imageboxButtonFullscreen: '.playroom-imagebox-fullscreen',
        imageboxButtonShare: '.playroom-imagebox-share',
        imageboxButtonClose: '.playroom-imagebox .popup-close',
        popupImageboxFullscreen: '#playroom-imagebox-fullscreen',
        popupImageboxFullscreenWrapper: '#playroom-imagebox-fullscreen .popup-fullscreen-wrapper',
        popupDocumentShare: '.document-share-popup',
    };

    $(window).resize(function () {
        PlayroomSetup();
    });
    PlayroomSetup();

    $('.playroom-pointer[href], .playroom-room-name[href]').click(function () {
        const isJavascript = $(this).attr('href') == 'javascript:void(0)';

        if (!isJavascript) $('.playroom').addClass('is-loading');
    });

    $('.playroom-pointer[data-type="image"]').click(function (e) {
        e.preventDefault();
        const $imagebox = $('.playroom-imagebox');
        const $title = $('.playroom-imagebox-title');
        const $description = $('.playroom-imagebox-description');
        const $image = $('.playroom-imagebox-image');
        const title = $(this).data('title');
        const description = $(this).data('description');
        const image = $(this).data('image');
        const isFullscreen = $(this).data('fullscreen') !== undefined;
        const newImage = new Image();

        // This is to determine whether tutorial can be shown or not
        // because Tutorial should only be open only if $imagebox is open
        // and $image has been loaded successfully
        // `I` stands for `imagebox`
        let hasIOpened = false;
        let hasILoaded = false;

        const transitionEndEvent = getTransitionEndEvent();

        // Show Tutorial if has never viewed before
        const showTutorial = () => {
            if (!hasIOpened) return false;
            if (!hasILoaded) return false;

            if (title.toLowerCase() == 'digiboard' && viewedTutorial.indexOf('digiboard') == -1) {
                showDigiboardTutorial();
                viewedTutorial.push('digiboard');
            } else if (viewedTutorial.indexOf('save') == -1) {
                showSaveTutorial();
                viewedTutorial.push('save');
            }

            // Deregister transition event
            $imagebox.off(transitionEndEvent, onFinishTransition);
        };

        const onFinishTransition = () => {
            // Prevent prematurely show tutorial when the email hasn't open yet
            if (!$imagebox.hasClass('is-open')) return false;

            hasIOpened = true;
            showTutorial();
        };

        $imagebox.on(transitionEndEvent, onFinishTransition);

        newImage.src = image;
        newImage.onload = function () {
            $image.removeClass('is-loading');
            hasILoaded = true;
            showTutorial();
        };

        if (isFullscreen) {
            $imagebox.addClass('is-fullscreen');
        } else {
            $imagebox.removeClass('is-fullscreen');
        }

        $title.html(title);
        $description.html(description);
        $image.find('img').remove();
        $image.addClass('is-loading').append(newImage);
        $imagebox.addClass('is-open');
        $(elms.imageboxButtonShare).data('url', image);
        $(elms.imageboxButtonShare).data('title', title);
        share.hideShareButtonIfShared();

        if (title.toLowerCase() == "ceo's laptop" || title.toLowerCase() == "chairwoman's laptop") {
            $imagebox.addClass('is-ceo-laptop');
        } else {
            $imagebox.removeClass('is-ceo-laptop');
        }
    });

    $(elms.imageboxButtonClose).click(function (e) {
        $(elms.imagebox).removeClass('is-open');
    });

    $(elms.imageboxButtonFullscreen).click(function () {
        const $selectedImage = $(elms.imageboxImg);
        const $existingImage = $(`${elms.popupImageboxFullscreenWrapper} img`);

        $existingImage.removeClass('is-active');
        $selectedImage.clone(true).addClass('is-active').appendTo(elms.popupImageboxFullscreenWrapper);
        $(elms.popupImageboxFullscreen).addClass('is-open');
    });

    $(elms.imageboxButtonShare).click(function () {
        const url = $(this).data('url');
        const title = $(this).data('title');
        share.document(url, title);
        $(elms.imageboxButtonShare).addClass('is-hidden');
        $(elms.popupDocumentShare).addClass('is-open');
    });

    $(elms.imageboxScrolling).on('scroll', function () {
        const scrolltop = $(elms.imageboxScrolling).scrollTop();
        const contentTop = $(elms.imageboxContent).position().top;
        const contentHeight = $(elms.imageboxContent).height();
        const contentOutsideScroll = contentTop + contentHeight;
        const isContentOutViewport = scrolltop > contentOutsideScroll;
        if (isContentOutViewport) {
            $(elms.imageboxBar).addClass('is-open');
        } else {
            $(elms.imageboxBar).removeClass('is-open');
        }

        // console.log($(elms.imageboxScrolling).scrollTop(), $(elms.imageboxContent).position().top);
    });

    const showDigiboardTutorial = () => {
        if (typeof tutorialObj['digiboard'] == 'undefined') {
            tutorialObj.digiboard = new Tutorial({
                id: '#tutorial-digiboard',
                highlighted: '.playroom-imagebox-image img',
                onClose: () => {
                    tutorialObj.digiboard.onViewedTutorial('digiboard');
                    if (viewedTutorial.indexOf('save') == -1) {
                        showSaveTutorial();
                    }
                },
            });
        }
        tutorialObj.digiboard.open();
    };

    const showSaveTutorial = () => {
        if (typeof tutorialObj['save'] == 'undefined') {
            tutorialObj.save = new Tutorial({
                id: '#tutorial-save',
                highlighted: '.playroom-imagebox-actions',
                onClose: () => {
                    tutorialObj.save.onViewedTutorial('save');
                },
            });
        }
        tutorialObj.save.open();
    };
});
