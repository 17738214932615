/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 *
 * @param {number} min Minimum range
 * @param {number} max Maximum range
 */
const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
const AspirationUI = {
    // List of available {x, y} coordinate
    availableCoordinate: [],
    // Current key number applied to the latest AspirationItem
    currentMaxKey: 0,
    // List key of all AspirationItem
    itemKeys: [],
    setup: function () {
        const $aspiration = $('.aspiration');
        const $aspirationInner = $('.aspiration-inner');
        const $aspirationItem = $('.aspiration-inner .aspiration-item');
        const itemWidth = 340;
        const itemHeight = 120;
        const xIndexTotal = Math.floor($aspirationInner[0].clientWidth / itemWidth);
        const yIndexTotal = Math.floor($aspirationInner[0].clientHeight / itemHeight);

        //console.log('inner-height: ', $aspirationInner[0].clientHeight);

        // Will be filled with list of X,Y combination
        let initAvailableCoordinate = [];
        // Will be filled with number key of all items
        let initItemKeys = [];
        /**
         * Set inner size to fit with the maximum item possible, so we can
         * make it center perfectly
         */
        $aspirationInner.css({
            height: `${yIndexTotal * itemHeight}`,
            width: `${xIndexTotal * itemWidth}`,
        });
        // Listing all the available coordinate
        for (let xIndex = 0; xIndex < xIndexTotal; xIndex++) {
            for (let yIndex = 0; yIndex < yIndexTotal; yIndex++) {
                const newCoordinate = {
                    x: itemWidth * xIndex,
                    y: itemHeight * yIndex,
                };
                initAvailableCoordinate.push(newCoordinate);
            }
        }
        // console.log('avail init', initAvailableCoordinate);
        $aspirationItem.each(function (index) {
            if (initAvailableCoordinate.length > 0) {
                // Still have availableCoordinate slots
                const maxRandomIndex = initAvailableCoordinate.length - 1;
                const randomIndex = getRandomInt(0, maxRandomIndex);
                // Get one coordinate and remove it from availableCoordinate list
                const coordinate = initAvailableCoordinate.splice(randomIndex, 1)[0];

                // console.log('avail', initAvailableCoordinate);

                $aspiration.removeClass('is-overload');
                initItemKeys.push(index);
                $(this).addClass('key-' + index);
                $(this).removeClass('is-overload');
                $(this).css({
                    height: itemHeight,
                    width: itemWidth,
                    top: coordinate.y + 'px',
                    left: coordinate.x + 'px',
                });
            } else {
                // Overload
                const $overload = $('.aspiration-inner .aspiration-item.is-overload');
                const left = 8 + 25 * $overload.length;
                $aspiration.addClass('is-overload');
                $(this).addClass('is-overload');
                $(this).css({ left: left + 'px' });
                // console.log('overload: ', index);
            }
        });

        // console.log('inner-height: ', $aspirationInner[0].clientHeight);

        this.availableCoordinate = initAvailableCoordinate;
        this.itemKeys = initItemKeys;
        this.currentMaxKey = initItemKeys.length - 1;
        setTimeout(() => {
            $aspiration.removeClass('is-loading');
            // console.log('inner-height: ', $aspirationInner[0].clientHeight);
        }, 500);
    },
    append: function (name, message, avatar) {
        const availableCoordinate = this.availableCoordinate;
        const isAvailable = availableCoordinate.length > 0;
        const $aspiration = $('.aspiration');
        const $aspirationInner = $('.aspiration-inner');
        const $oldestAspirationItem = $('.aspiration-inner .aspiration-item').first();
        const $clonedItem = $('.aspiration-tobe-cloned .aspiration-item');
        const $newAspirationItem = $clonedItem.clone();
        const currentKey = this.currentMaxKey + 1;
        const oldestKey = this.itemKeys[0];
        avatar = avatar
            ? avatar
            : 'https://cdn-images-1.medium.com/fit/c/100/100/1*3ApyPDl5_ovc87cyvX7MMw.jpeg';
        let coordinate;

        if (isAvailable) {
            const maxRandomIndex = availableCoordinate.length - 1;
            const randomIndex = getRandomInt(0, maxRandomIndex);
            // Get one coordinate and remove it from availableCoordinate list
            coordinate = availableCoordinate.splice(randomIndex, 1)[0];
        } else {
            /**
             * No available coordinate left, so we need to remove the
             * oldest aspiration in order to push this aspiration
             */

            //  If there's no aspiration before this, CSS layout is messed up
            if (!$('.aspiration-inner .aspiration-item').length) {
                return console.log('Bug: layout broken');
            }

            /**
             * Get oldestAspirationItem's coordinate and use it as
             * coordinate for the new item
             */
            coordinate = {
                x: $oldestAspirationItem.position().left,
                y: $oldestAspirationItem.position().top,
            };
            $oldestAspirationItem.remove();
            this.itemKeys.splice(0, 1);
        }

        this.currentMaxKey = currentKey;
        this.itemKeys.push(currentKey);
        $aspiration.removeClass('is-overload');
        // Remove attribute from cloned object
        $newAspirationItem.removeClass('is-overload');
        $newAspirationItem.removeClass('key-' + oldestKey);
        // Setup the string
        $newAspirationItem.find('.aspiration-person-name').html(name);
        $newAspirationItem.find('.aspiration-person-aspiration').html(message);
        $newAspirationItem.find('.aspiration-person-avatar').attr('src', avatar);
        $newAspirationItem.css({
            top: coordinate.y + 'px',
            left: coordinate.x + 'px',
        });
        // console.log('availableCoordinate', availableCoordinate);
        // console.log('coordinate', coordinate);
        // Set class for key tracking and animation
        $newAspirationItem.addClass('is-appended');
        $newAspirationItem.addClass('key-' + currentKey);
        $aspirationInner.append($newAspirationItem);
    },
};
