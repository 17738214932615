var iPad = {};

$(document).render(function () {
    const elms = {
        ipadOpen: '[data-open="popup"][data-target="#popup-ipad"]',
        ipadClose: '#popup-ipad .popup-close',
        ipadConnectU: '.ipad-connectu',
        ipadConnectUOpen: '[data-open="connectu"]',
        ipadConnectUClose: '[data-dismiss="connectu"]',
        ipadConnectUFullscreen: '.ipad-connectu-fullscreen',
        ipadBrowser: '.ipad-browser',
        ipadBrowserOpen: '[data-open="browser"]',
        ipadBrowserClose: '[data-dismiss="browser"]',
        ipadBrowserFullscreen: '.ipad-browser-fullscreen',

        popupFullscreenConnectU: '#popup-fullscreen-connectu',
        popupFullscreenBrowser: '#popup-fullscreen-browser',

        // Sometimes there's double escape, this is the main escape of the page
        mainEscape: '#easyspa-container > div > .escape',
    };


    $(elms.ipadOpen).click(function () {
        $(elms.mainEscape).addClass('is-hidden');
    });

    $(elms.ipadClose).click(function () {
        $(elms.mainEscape).removeClass('is-hidden');
    });

    // // TODO: Tutorial
    // const tutorialConnectu = new Tutorial({
    //     id: '#tutorial-save-ipad-connectu',
    //     highlighted: '.ipad-connectu-actions',
    // });
    $(elms.ipadConnectUOpen).click(function () {
        $(elms.ipadConnectU).addClass('is-open');
        share.hideShareButtonIfShared();
        
        // // TODO: Tutorial
        // setTimeout(() => {
        //     $('body')[0].offsetWidth;
        //     tutorialConnectu.open();
        // }, 278);
    });

    $(elms.ipadConnectUClose).click(function () {
        $(elms.ipadConnectU).removeClass('is-open');
    });

    $(elms.ipadConnectUFullscreen).click(function () {
        $(elms.popupFullscreenConnectU).addClass('is-open');
    });

    // // TODO: Tutorial
    // const tutorialBrowser = new Tutorial({
    //     id: '#tutorial-save-ipad-browser',
    //     highlighted: '.ipad-browser-actions',
    // });
    $(elms.ipadBrowserOpen).click(function () {
        $(elms.ipadBrowser).addClass('is-open');
        share.hideShareButtonIfShared();

        // // TODO: Tutorial
        // setTimeout(() => {
        //     $('body')[0].offsetWidth;
        //     tutorialBrowser.open();
        // }, 278);
    });

    $(elms.ipadBrowserClose).click(function () {
        $(elms.ipadBrowser).removeClass('is-open');
    });

    $(elms.ipadBrowserFullscreen).click(function () {
        $(elms.popupFullscreenBrowser).addClass('is-open');
    });

    $('.ipad-connectu-share').click(function () {
        $('.document-share-popup').addClass('is-open');
        share.document($(this).data('url'), $(this).data('title'));
    });

    $('.ipad-browser-share').click(function () {
        $('.document-share-popup').addClass('is-open');
        share.document($(this).data('url'), $(this).data('title'));
    });
});
