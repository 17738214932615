$(document).render(async function () {
    const isLobbyPage = $('#lobby-page').length > 0;
    if (!isLobbyPage) return false;

    $('.teamMembers__notifications').hide();
    try {
        await fbDB.initializeGame();

        // auto join if no player left in the game
        if (fbDB.playerOnline.length < 1) {
            $.request('onAcceptJoinStartedGame', {
                data: { user_id: viewData.user.id },
                success: function (res) {
                    if (res.success) {
                    } else {
                        console.log('An error occured');
                    }
                },
                error: function (err) {
                    console.log('An error occured : ', err);
                },
                complete: function (res) {
                    $('#new-join-' + user_id).remove();
                    if (!$('.new-member-list').length) {
                        $('#newMembers-popup').removeClass('is-open');
                    }
                },
            });
        } else {
            $('.teamMembers__notifications').show();
        }
    } catch (e) {
        console.log(e);
    }

    $('.teamMembers ').hide();

    $('.js-request-join').on('click', function (e) {
        e.preventDefault();
        $.request('onRequestJoinStartedGame', {
            data: {},
            success: function (res) {
                if (res.success) {
                    const $timer = $('#timer-auto-cancel');
                    let diff = res.shouldAcceptedBefore - res.requestedAt;
                    let timer = setInterval(function () {
                        diff--;
                        let minutes = Math.floor(diff / 60);
                        let seconds = Math.abs(diff % 60);
                        $timer.text(
                            `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
                        );
                        if (diff <= 0) {
                            clearInterval(timer);
                            $('.js-cancel-join').trigger('click');
                        }
                    }, 1000);
                } else {
                    console.log('An error occured');
                }
            },
            error: function (err) {
                console.log('An error occured : ', err);
            },
            complete: function (res) {},
        });

        $('.teamMembers__notifications').addClass('is-hidden');
    });

    $('.js-cancel-join, .cancel-join-started-game').on('click', function () {
        $.request('onCancelJoinStartedGame', {
            data: {},
            success: function (res) {
                if (res.success) {
                    // $('.teamMembers__notifications').removeClass('is-hidden');
                    location.href = '/';
                } else {
                    console.log('An error occured');
                }
            },
            error: function (err) {
                console.log('An error occured : ', err);
            },
            complete: function (res) {},
        });
    });
});
