(function ($, undefined) {
    // The global bdjs object
    var bdjs = {};

    // Set as a browser global
    window.bdjs = bdjs;

	/**
	*  strReplace
	*
	*  Performs a string replace
	*
	*  @param	string search
	*  @param	string replace
	*  @param	string subject
	*  @return	string
	*/

    bdjs.strReplace = function (search, replace, subject) {
        return subject.split(search).join(replace);
    };

	/**
	*  uniqueId
	*
	*  Returns a unique ID
	*
	*  @param	string prefix Optional prefix.
	*  @return	string
	*/

    var idCounter = 0;
    bdjs.uniqueId = function (prefix) {
        var id = ++idCounter + '';
        return prefix ? prefix + id : id;
    };

    bdjs.isUndefined = function(value){
        return typeof value == 'undefined';
    };

	/**
	*  parseArgs
	*
	*  Merges together defaults and args much like the WP wp_parse_args function

	*  @param	object args
	*  @param	object defaults
	*  @return	object
	*/

	bdjs.parseArgs = function( args, defaults ){
		if( typeof args !== 'object' ) args = {};
		if( typeof defaults !== 'object' ) defaults = {};
		return $.extend({}, defaults, args);
	}


    // Global component storage
    bdjs.components = [];

    /**
	*  registerComponent
	*
    *  register new bd component.
    
	*  @param	object component
	*  @return	void
	*/
    bdjs.registerComponent = function (component) {
        bdjs.components.push(component);
    };

	/**
	*  getComponents
	*
	*  Get all component
	*  @return	object
	*/

    bdjs.getComponents = function () {
        return bdjs.components;
    }

	/**
	*  getComponent
	*
	*  Get component by id
	*
	*  @param	string id
	*  @return	object
	*/

    bdjs.getComponent = function (id) {
        var component = null;

        bdjs.components.forEach(function (item, index) {
            if (item.id == id) {
                component = bdjs.components[index];
            }
        });

        return component;
    }

    /**
	 * Make ajax request
	 * @return {Promises}
	 */
    bdjs.makeRequest = function (URL, objData) {
		const request = axios.post;

		const formData = new FormData();

		for (const prop in objData) {
			formData.append(prop, objData[prop]);
		}
		
		return request( URL, formData, { "Content-Type": "multipart/form-data" });
    }

    bdjs.removeItemArray = function(arr, value) {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }

        return arr;
    }

	bdjs.isArray = function (any) {
		// Only implement if no native implementation is available
		if (typeof Array.isArray === "undefined") {
			Array.isArray = function (obj) {
				return Object.prototype.toString.call(obj) === "[object Array]";
			};
		}
		return Array.isArray(any);
    }
    
    bdjs.keyLength = function(object) {
        return Object.keys(object).length;
	}

	bdjs.showLoading = function () {
		return $(".transitionPage").addClass("is-active");
	}

	bdjs.hideLoading = function() {
		return $(".transitionPage").removeClass("is-active");
	}

	bdjs.listen = function(eventName, callback) {
		return  $(document).on(eventName, callback);
	}

	bdjs.trigger = function(eventName, data) {
		return $(document).trigger(eventName, {data : data});
	}

	/**
	 * @description ### Returns Go / Lua like responses(data, err)
	 * when used with await
	 *
	 * - Example response [ data, undefined ]
	 * - Example response [ undefined, Error ]
	 *
	 *
	 * When used with Promise.all([req1, req2, req3])
	 * - Example response [ [data1, data2, data3], undefined ]
	 * - Example response [ undefined, Error ]
	 *
	 *
	 * When used with Promise.race([req1, req2, req3])
	 * - Example response [ data, undefined ]
	 * - Example response [ undefined, Error ]
	 *
	 * @param {Promise} promise
	 * @returns {[undefined |  any, Error |  undefined]} [ data, err ]
	 */
	bdjs.goAsync = (promise) => {
		return promise
		.then((data) => [data, undefined])
		.catch((err) =>
			Promise.resolve([
			undefined,
	
			// if error happen but we got falsy value for the `error` variable, then
			// return true, so we can catch the error in the next code
			err || true,
			]),
		);
	};

})(jQuery);