$(document).render(function () {
    function tooltip($tooltip) {
        let popperInstance;
        const $toggle = $tooltip.find('.tooltip-toggle');
        const $box = $tooltip.find('.tooltip-box');
        const defaultPlacement = 'top-start';

        function create() {
            popperInstance = Popper.createPopper($toggle[0], $box[0], {
                placement: defaultPlacement,
            });
        }

        function destroy() {
            if (popperInstance) {
                popperInstance.destroy();
                popperInstance = null;
            }
        }

        function show() {
            $tooltip.addClass('is-open');
            create();
        }

        function hide() {
            $tooltip.removeClass('is-open');
            destroy();
        }

        // Events
        $toggle.hover(show);
        $toggle.on('focus', show);
        $toggle.on('mouseleave', hide);
        $toggle.on('blur', hide);
    }

    $('.tooltip').each(function () {
        tooltip($(this));
    });
});
