var showMessage = (message) => {
    $('.snackbar').find('.snackbar-body').html(message);
    $('.snackbar').addClass('is-active');
};

var hideMessage = () => {
    $('.snackbar').removeClass('is-active');
};

$(document).render(function() {
    $('.snackbar-close').click(function () {
        const $snackbar = $(this).closest('.snackbar');
        $snackbar.removeClass('is-active');
    });
});
