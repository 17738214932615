$(document).render(function () {
    const isSubscribeLeaderPage = $('#subscribe-leader').length > 0;
    if (!isSubscribeLeaderPage) return false;

    const elms = {
        form: 'form.subscribe-steps',
        stepTitle: '.step-title',
        dateWrapper: '.future',
        inputRoom: 'input[name="room"]',
        inputParticipant: 'input[name="participant"]',
        inputEmailSubject: 'input[name="email-subject"]',
        inputEmailVCLink: 'input[name="email-link"]',
        inputEmailMessage: 'textarea[name="email-message"]',
        inputDate: 'input[name="future"]',
        inputDateStart: 'input[name="future-start"]',
        inputDateEnd: 'input[name="future-end"]',
        submit: 'button[type="submit"]',
        submitHelper: '.step-proceed-info',
        participantWrapper: '.participant-wrapper',
        participantItem: '.participant-item',
        participantAdd: '.add-participant',
        participantRemove: '.remove-participant',
        snackbar: '.snackbar',
        msg_leaderAsParticipant: '#leader-as-participant-msg',
        gotoSubscribeAvatar: '#go-to-subscribe-avatar',
    };

    // Available options, see: https://flatpickr.js.org/plugins/#confirmdate
    const datePickerOptions = {
        enableTime: true,
        altInput: true,
        altFormat: 'F j, Y | H:i',
        dateFormat: 'Y-m-d H:i:S',
        minDate: 'today',
        plugins: [
            new confirmDatePlugin({
                confirmIcon: '',
                confirmText: 'Submit',
                showAlways: true,
            }),
        ],
    };

    /**
     * To validate all the field
     * @returns {boolean}
     */
    const validateForm = () => {
        const room = $(elms.inputRoom).val();
        const emailSubject = $(elms.inputEmailSubject).val();
        const emailLink = $(elms.inputEmailVCLink).val();
        const emailMessage = $(elms.inputEmailMessage).val();
        let participantList = [];

        /**
         * To disable submit button and show error message
         * @param {string} message
         */
        const throwError = (message) => {
            $(elms.submitHelper).html(message).addClass('is-active');
            $(elms.submit).prop('disabled', true);
        };

        // Collect all participants email
        $(elms.inputParticipant).each(function () {
            const participant = $(this).val();

            // If empty, don't add it up
            if (participant.length) participantList.push(participant);
        });

        if (!room) {
            // Invalid participant
            return throwError('Fill room name');
        }

        if (!participantList || participantList.length < 1) {
            // Invalid participant
            return throwError('Add participant');
        }

        if (!emailSubject) {
            // Invalid email subject
            return throwError('Fill email subject');
        }

        if (!emailLink) {
            // Invalid email link
            return throwError('Fill video call link');
        }

        if (!emailMessage) {
            // Invalid email message
            return throwError('Fill email message');
        }

        // All the data valid
        $(elms.submitHelper).removeClass('is-active');
        $(elms.submit).prop('disabled', false);
        return true;
    };

    const showMessage = (message) => {
        $(elms.snackbar).find('.snackbar-body').html(message);
        $(elms.snackbar).addClass('is-active');
    };

    const hideMessage = () => {
        $(elms.snackbar).removeClass('is-active');
    };

    const isEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const emailDomainCheck = (email, domain) => {
        var parts = email.split('@');
        if (parts.length === 2) {
            if (parts[1] === domain) {
                return true;
            }
        }
        return false;
    };

    const getDomain = (email) => {
        var parts = email.split('@');
        if (parts.length === 2) {
            return parts[1];
        }
        return false;
    };

    // const checkIsRestricted = (email_domain) => {
    //     // emailDomains defined inside subscribe-leader.htm
    //     var whiteListDomain = emailDomains.split(',');

    //     var isRestricted = true;

    //     if (whiteListDomain && whiteListDomain.length) {
    //         whiteListDomain.forEach(function (val, i) {
    //             val = val.replace(/\/$/, '');
    //             if (val == email_domain) {
    //                 isRestricted = false;
    //             }
    //         });
    //     }

    //     return isRestricted;
    // };

    const onDateChange = function () {
        const isChecked = $(elms.inputDate).prop('checked') == true;

        if (isChecked) {
            $(elms.dateWrapper).addClass('is-selected');
            $(elms.dateWrapper).find('input[type="text"]').prop('disabled', false);
        } else {
            $(elms.dateWrapper).removeClass('is-selected');
            $(elms.dateWrapper).find('input[type="text"]').prop('disabled', true);
        }
    };

    const onParticipantAdd = function () {
        const $allParticipants = $(elms.participantItem);
        const $participant = $allParticipants.first().clone();

        if ($allParticipants.length >= 50) {
            showMessage('Maximum participants exceeded');
            return false;
        }

        $participant.find('input').val('');
        $participant.find(elms.participantRemove).prop('disabled', false);
        $participant.appendTo(elms.participantWrapper);
        $(elms.participantRemove).prop('disabled', false);
        $(elms.participantItem).last().find('input').focus();
    };

    const onParticipantKeypress = function (e) {
        const key = e.which || e.keycode || 0;
        const isEnterKey = key == 13;
        if (isEnterKey) {
            onParticipantAdd();
            return false;
        }
    };

    const onParticipantRemove = function () {
        const $participant = $(this).parent(elms.participantItem);
        const participantsLength = $(elms.participantItem).length;
        const isLast = participantsLength - 1 == 1;

        $participant.remove();
        if (isLast) {
            // console.log(participantsLength);
            $(elms.participantRemove).prop('disabled', true);
        }
    };

    const isValidURL = function (string) {
        var res = string.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        );
        return res !== null;
    };

    const strTrim = function(x) {
        return x.replace(/^\s+|\s+$/gm,'');
    }

    const onSubmit = (e) => {
        var members = [];
        var is_valid = true;
        var is_future = $(elms.inputDate).prop('checked');
        var start_date = $(elms.inputDateStart).val();
        var end_date = $(elms.inputDateEnd).val();
        var video_call_link = $(elms.inputEmailVCLink).val();

        e.preventDefault();

        if (!validateForm()) return false;
        if ($(elms.submit).hasClass('is-loading')) return false;

        $(elms.submit).addClass('is-loading');
        hideMessage();

        $(elms.inputParticipant).each(function (index, el) {
            var email = $(this).val();
            // const domain = getDomain(email);
            // const isRestricted = checkIsRestricted(domain);

            email = strTrim(email);
            if (email) {
                if (!isEmail(email)) {
                    showMessage(email + ' is not an email address');
                    is_valid = false;
                    return false;
                } else if (members.indexOf(email) > -1) {
                    showMessage('Duplicate email : ' + email);
                    is_valid = false;
                    return false;
                } else if (email === pageData.leaderEmail) {
                    showMessage($(elms.msg_leaderAsParticipant).text());
                    is_valid = false;
                    return false;
                } 
                // else if (isRestricted) {
                //     showMessage('Email domain [' + domain + '] is not registered.');
                //     is_valid = false;
                //     return false;
                // } 
                else {
                    members.push(email);
                }
            }
            else {
                showMessage('Participant\'s email address is required');
                $(elms.participantItem).last().find('input').focus();
                is_valid = false;
                return false;
            }
        });

        if (is_future && (!start_date || !end_date)) {
            showMessage('Please fill start and end date');
            is_valid = false;
        }

        if (!isValidURL(video_call_link)) {
            showMessage('Invalid video call URL');
            is_valid = false;
        }

        if (!is_valid) {
            $(elms.submit).removeClass('is-loading');
            return false;
        }

        var ajaxProps = {
            room: $(elms.inputRoom).val(),
            email_subject: $(elms.inputEmailSubject).val(),
            email_message: $(elms.inputEmailMessage).val(),
            video_call_link: video_call_link,
            start_date: start_date,
            end_date: end_date,
            members: members,
            is_future: is_future,
        };

        analytic.logEvent('create_room', { name: $(elms.inputRoom).val()});
        analytic.logEvent('send_email', { subject: ajaxProps.email_subject, video_call_link: ajaxProps.video_call_link, message: ajaxProps.email_message });
        for(let member of members) {
	        	analytic.logEvent('invite_player', { email_address: member});
        }

        $.request('onCreateRoom', {
            data: ajaxProps,
            success: function (data) {
                if (data.success) {
                		for(let user of data.new_users) {
                				analytic.setUserProperties({
                					id: user.id,
                					created_at: user.created_at,
                					group_leader: 0,
                					browser: helper.getBrowser(),
                				});
                		}

                    $(elms.gotoSubscribeAvatar).click();
                    //const checkEmailMsg = $('#check-email-msg').text();
                    // showMessage(checkEmailMsg);
                    // $('input, button, textarea').attr('disabled', 'disabled');
                }
                else {
                    showMessage(data.error_msg);
                }
            },
            error: function (error) {
                try {
                    showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                } catch (e) {
                    showMessage('Oops, an error occured.');
                }
            },
            complete: function () {
                $(elms.submit).removeClass('is-loading');
            },
        });
    };

    let alignStepTitle_lastHeight = 0;

    const alignStepTitle = () => {
        let minHeight = 0;

        $(elms.stepTitle).each(function () {
            const spanHeight = $(this).find('span').height();
            if (spanHeight > minHeight) minHeight = spanHeight;
        });

        if (alignStepTitle_lastHeight !== minHeight) {
            alignStepTitle_lastHeight = minHeight;
            $(elms.stepTitle).css('min-height', minHeight);
        }
    };

    // Example change minDate / endDate on-the-fly
    // see: https://github.com/flatpickr/flatpickr/issues/1380#issuecomment-390968394
    let startDatePicker = flatpickr(elms.inputDateStart, {
        ...datePickerOptions,
        onClose: function (selectedDates, dateStr, instance) {
            endDatePicker.set('minDate', dateStr);
        },
    });

    let endDatePicker = flatpickr(elms.inputDateEnd, datePickerOptions);

    validateForm();
    alignStepTitle();
    $(window).on('resize', alignStepTitle);
    $(elms.inputRoom).on('input', validateForm);
    $(elms.inputParticipant).on('input', validateForm);
    $(elms.inputEmailSubject).on('input', validateForm);
    $(elms.inputEmailVCLink).on('input', validateForm);
    $(elms.inputEmailMessage).on('input', validateForm);
    $(elms.inputDate).on('change', onDateChange);
    $(document).on('keypress', elms.inputParticipant, onParticipantKeypress);
    $(elms.participantAdd).on('click', onParticipantAdd);
    $(document).on('click', elms.participantRemove, onParticipantRemove);
    $(elms.form).on('submit', onSubmit);
});
