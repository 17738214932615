(function ($) {
    $('.page-item.custom').on('click', function (e) {
        const $this = $(this);
        $this.addClass('active');
        $this.siblings().removeClass('active');

        const page = $(this).attr('page');

        var url_string = window.location.href;
        var url = new URL(url_string);
        var is_all = url.searchParams.get('is_all');
        if (is_all && is_all == 1) {
            url.searchParams.set('is_all', 1);
        }
        url.searchParams.set('c_page', page);

        location.href = url;
    });
})(jQuery);
