/*
	to create a log:
		analytic.logEvent('waiting_room', { });
		analytic.logEvent('create_room', { name: 'room 1'});
*/

const analytic = {
    setup: () => {
        if (typeof firebase == 'undefined' || !fbConfig.enableAnalytic) return;

        if (firebase.apps.length === 0) {
            var firebaseConfig = {
                apiKey: fbConfig.apiKey,
                authDomain: fbConfig.authDomain,
                databaseURL: fbConfig.databaseURL,
                projectId: fbConfig.projectId,
                storageBucket: fbConfig.storageBucket,
                messagingSenderId: fbConfig.messagingSenderId,
                appId: fbConfig.appId,
                measurementId: fbConfig.measurementId,
            };

            firebase.initializeApp(firebaseConfig);
        }

        firebase.analytics();
        analytic.logEvent = firebase.analytics().logEvent;
        analytic.setUserProperties = firebase.analytics().setUserProperties;

        const urlParams = new URLSearchParams(window.location.search);
        const logEventParam = urlParams.get('login-from-token');
        if (logEventParam) {
            analytic.logEvent('sign_in', {});
        }
    },
    logEvent: (event) => {},
    setUserProperties: (event) => {},
};

analytic.setup();
