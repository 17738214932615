$(document).render(function () {
    const isDocumentExist = $('.document').length > 0;
    if (!isDocumentExist) return false;

    const elms = {
        document: '.document',
        documentPopup: '.document-popup',
        documentClose: '.document-popup .popup-close',
        documentItemOpen: '.document-list-item',
        documentItemClose: '.document-right-close',
        documentViewer: '.document-right.single',

        // action inside the item list itself (before is-viewing state)
        documentItemShare: '.document-action-share',

        // action inside the viewer (right-side)
        documentViewerShare: '.document-left-share',
        documentViewerFullscreen: '.document-left-fullscreen',

        // Sometimes there's double escape, this is the main escape of the page
        mainEscape: '#easyspa-container > div > .escape',

        popupFullscreen: '.document-fullscreen-popup',
        popupSave: '.document-save-popup',
        popupShare: '.document-share-popup',
        popupOpen: '[data-open="popup"][data-target]',
        laptopPopup: '#popup-laptop',
    };

    const saveDocument = (doc_url, doc_title) => {
        if (!doc_url) {
            return false;
        }
        var ajaxProps = {
            doc_url: doc_url,
            doc_title: doc_title,
        };

        $.request('onSaveDocument', {
            data: ajaxProps,
            success: function (data) {
                // const htmls = data.map(
                //     (doc) => `
                //     <div class="swiper-slide" data-title="${doc.title}" style="background-image:url(${doc.url})" doc_url="${doc.url}"></div>
                // `,
                // );
                // toolboxPopupSlider.removeAllSlides();
                // for (let html of htmls) {
                //     const node = new DOMParser().parseFromString(html, 'text/html').body.childNodes[0];
                //     toolboxPopupSlider.appendSlide(node);
                // }
                // toolboxPopupSlider.update();
            },
            error: function (error) {
                console.log(error);
            },
            complete: function () {},
        });
    };

    $(elms.popupOpen).click(function () {
        const dataTarget = $(this).data('target');
        const isDocument = $(dataTarget).hasClass('document-popup');

        // We need to check it first because the selector is generalized
        // and can be any element that trigger any kind of popup
        if (isDocument) $(elms.mainEscape).addClass('is-hidden');
    });

    $(elms.documentClose).click(function () {
    		$(elms.documentItemClose).click()
        $('img.is-open').removeClass('is-open')
        const isLaptopOpen = $(elms.laptopPopup).hasClass('is-open');
        if (!isLaptopOpen) $(elms.mainEscape).removeClass('is-hidden');
    });

    $(elms.documentItemOpen).click(function (e) {
        e.preventDefault();

        const link = $(this).data('link');
        const isButtonChild = $(e.target).parents('button').length;
        const isButtonRelated = isButtonChild || $(e.target).is('button');
        const parentId = $(this).closest('.popup').attr('id');

        /**
         * if user try to click inline action directly (save / share) then we
         * don't need to view the document itself
         */
        if (isButtonRelated) return false;

        $(`#${parentId} ${elms.document}`).addClass('is-viewing');
        $(`#${parentId} ${elms.documentViewer}`).find('img.is-open').removeClass('is-open');
        $(`#${parentId} ${elms.documentViewer}`).find(`img[data-link="${link}"]`).addClass('is-open');

        // hide share-document-for-review if document already shared
        var docAlreadyShared = false;
        if (!viewData.team.saved_documents) return false;

        for (let savedDocument of viewData.team.saved_documents) {
            if (savedDocument.title == $(this).data('title')) {
                docAlreadyShared = true;
                break;
            }
        }

        if (docAlreadyShared) {
            $(elms.documentViewerShare).addClass('is-shared');
        } else {
            $(elms.documentViewerShare).removeClass('is-shared');
        }
    });

    $(elms.documentItemClose).click(function (e) {
        const parentId = $(this).closest('.popup').attr('id');
        $(`#${parentId} ${elms.document}`).removeClass('is-viewing');
    });

    $(elms.documentItemShare).click(function () {
        const parentId = $(this).closest('.popup').attr('id');
        const $currentDocument = $(this).closest(elms.documentItemOpen);
        const doc_url = $currentDocument.data('link');
        const doc_title = $currentDocument.data('title');

        $('.document-share-popup').addClass('is-open');
        share.document(doc_url, doc_title);
    });

    $(elms.documentViewerShare).click(function () {
        const parentId = $(this).closest('.popup').attr('id');
        const $currentDocument = $(`${elms.documentPopup}#${parentId} ${elms.documentViewer} img.is-open`);
        const doc_url = $currentDocument.attr('src');
        const doc_title = $currentDocument.attr('alt');

        $('.document-share-popup').addClass('is-open');
        $(elms.documentViewerShare).addClass('is-shared');
        share.document(doc_url, doc_title);
    });

    $(elms.documentViewerFullscreen).click(function () {
        const parentId = $(this).closest('.popup').attr('id');
        const $popup = $(`${elms.popupFullscreen}[data-parent-id="${parentId}"]`);
        const $currentDocument = $(`${elms.documentPopup}#${parentId} ${elms.documentViewer} img.is-open`);
        const doc_url = $currentDocument.attr('src');
        const doc_title = $currentDocument.attr('alt');
        
        $popup.find(`img`).removeClass('is-active');
        $popup.find(`img[src="${doc_url}"][alt="${doc_title}"]`).addClass('is-active');
        $popup.addClass('is-open');
    });

    realtime.hideShareDocumentBtnIfAlreadyShared();
});
