const helper = {
    removeItemOnce: (arr, value) => {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    },
    defaultAvatar: 'https://cdn-images-1.medium.com/fit/c/100/100/1*3ApyPDl5_ovc87cyvX7MMw.jpeg',
    safeUrl: (url) => {
        return url.replace(/ /g, '%20')
    },
    getBrowser: () => {
	    var ua = function(regexp) {return regexp.test(window.navigator.userAgent)}
	    switch (true) {
	        case ua(/edg/i): return "Microsoft Edge";
	        case ua(/trident/i): return "Microsoft Internet Explorer";
	        case ua(/firefox|fxios/i): return "Mozilla Firefox";
	        case ua(/opr\//i): return "Opera";
	        case ua(/ucbrowser/i): return "UC Browser";
	        case ua(/samsungbrowser/i): return "Samsung Browser";
	        case ua(/chrome|chromium|crios/i): return "Google Chrome";
	        case ua(/safari/i): return "Apple Safari";
	        default: return "Other";
	    }
  }
};


